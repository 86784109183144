import { DataTypes } from 'fitify-ui-landing/src/@types'

const featuresData: DataTypes.FeatureItem[] = [
  {
    title: 'hc_landing_feature_block_1_title',
    image: '/assets/images/features/1@2x.webp',
    imageSet:
      '/assets/images/features/1.webp 600w, /assets/images/features/1@2x.webp 2x, /assets/images/features/1@2x.webp 1200w',
    imageAssets: [
      {
        width: 110,
        label: 'Exercises',
        value: '25',
        icon: 'balance',
        iconColor: 'Blue400',
        position: [-10, undefined, undefined, 72],
      },
      {
        width: 120,
        label: 'Heart&nbsp;rate',
        value: '126',
        icon: 'heart',
        iconColor: 'Red400',
        position: [-7, undefined, undefined, 33],
      },
      {
        width: 129,
        label: 'Time&nbsp;in&nbsp;bed',
        value: '6:21',
        icon: 'sleep',
        iconColor: 'Blue400',
        position: [25, undefined, undefined, 14],
      },
      {
        width: 124,
        label: 'Water&nbsp;intake',
        value: '6/8',
        icon: 'water-intake',
        iconColor: 'Blue400',
        position: [30, undefined, undefined, 80],
      },
      {
        width: 130,
        label: 'Calories',
        value: '459',
        icon: 'fire',
        iconColor: 'Red400',
        position: [66, undefined, undefined, 70],
      },
      {
        width: 100,
        label: 'HRV',
        value: '74',
        icon: 'hrv',
        iconColor: 'Red400',
        position: [64, undefined, undefined, 32],
      },
    ],
    content: 'hc_landing_feature_block_1_text',
  },
  {
    title: 'hc_landing_feature_block_2_title',
    content: 'hc_landing_feature_block_2_text',
    image: '/assets/images/features/2@2x.webp',
    imageSet:
      '/assets/images/features/2.webp 600w, /assets/images/features/2@2x.webp 2x, /assets/images/features/2@2x.webp 1200w',
  },
  {
    title: 'hc_landing_feature_block_3_title',
    content: 'hc_landing_feature_block_3_text',
    image: '/assets/images/features/3@2x.webp',
    imageSet:
      '/assets/images/features/3.webp 600w, /assets/images/features/3@2x.webp, /assets/images/features/3@2x.webp 1200w',
  },
  {
    title: 'hc_landing_feature_block_4_title',
    content: 'hc_landing_feature_block_4_text_v1',
    image: '/assets/images/features/4@2x.webp',
    imageSet:
      '/assets/images/features/4.webp 600w, /assets/images/features/4@2x.webp, /assets/images/features/4@2x.webp 1200w',
  },
]

const businessFeaturesData: DataTypes.FeatureItem[] = [
  {
    title: 'hc_landing_work_feature_block_1_title',
    content: 'hc_landing_work_feature_block_1_subtitle',
    image: '/assets/images/features/business-1.webp',
    imageSet:
      '/assets/images/features/business-1.webp, /assets/images/features/business-1@2x.webp 2x, /assets/images/features/business-1@2x.webp 1200w',
  },
  {
    title: 'hc_landing_work_feature_block_2_title',
    content: 'hc_landing_work_feature_block_2_subtitle_v1',
    image: '/assets/images/features/business-2.webp',
    imageSet:
      '/assets/images/features/business-2.webp 600w, /assets/images/features/business-2@2x.webp 2x, /assets/images/features/business-2@2x.webp 1200w',
  },
  {
    title: 'hc_landing_work_feature_block_3_title',
    content: 'hc_landing_work_feature_block_3_subtitle',
    image: '/assets/images/features/business-3.webp',
    imageSet:
      '/assets/images/features/business-3.webp 600w, /assets/images/features/business-3@2x.webp, /assets/images/features/business-3@2x.webp 1200w',
  },
  {
    title: 'hc_landing_work_feature_block_4_title',
    content: 'hc_landing_work_feature_block_4_subtitle',
    image: '/assets/images/features/business-4.webp',
    imageSet:
      '/assets/images/features/business-4.webp 600w, /assets/images/features/business-4@2x.webp, /assets/images/features/business-4@2x.webp 1200w',
  },
]

export { featuresData, businessFeaturesData }
