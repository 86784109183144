import { ThemeVariants } from 'fitify-ui/src/theme'
import { DataTypes } from 'fitify-ui-landing/src/@types'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'
import { useTheme } from 'styled-components'

import Feature from './Feature'

const Features = ({ items }: { items: DataTypes.FeatureItem[] }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Section id={ELEMENT_IDS.features}>
      <Fade triggerOnce>
        <Container>
          {items.map((item: DataTypes.FeatureItem, index: number) => {
            return (
              <Feature
                key={'feature_' + index}
                title={t(item.title)}
                image={item.image}
                imageSet={item.imageSet}
                content={t(item.content)}
                isOdd={index % 2 === 1}
                imageAssets={item.imageAssets}
                headlineVariant={
                  (theme.variant === ThemeVariants.DIGITAL
                    ? 'dg-feature-title'
                    : 'feature-title') as TextTypesVariants
                }
              />
            )
          })}
        </Container>
      </Fade>
    </Section>
  )
}

export default Features
