import {
  biggerPhone,
  phone,
  tabletPortrait,
  xsPhone,
} from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css, type RuleSet } from 'styled-components'

export const additionalImageStyles = css`
  ${phone(css`
    object-position: 300px;
    transform: scale(1.2);
    object-fit: contain;
    height: 227px;
  `)}

  ${xsPhone(css`
    object-fit: contain;
  `)}
`

export const VisualStyledImage = styled.img<{
  additionalStyles?: RuleSet
  isBusiness?: boolean
}>`
  ${tabletPortrait(css`
    object-fit: cover;
  `)}
  ${biggerPhone(css`
    object-position: -70px;
    object-fit: cover;
  `)}

  ${phone(css`
    object-position: -100px;
    object-fit: contain;
    height: 227px;
  `)}

  ${(props) =>
    props.isBusiness &&
    phone(css`
      object-position: -70px;
      height: 240px;
    `)}

  ${(props) => props.additionalStyles}
`
