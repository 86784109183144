import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const StyledAnimatedGroup = styled(motion.div)`
  position: relative;
  border-radius: 25px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;

  ${phone(css`
    grid-auto-flow: row;
    row-gap: 1rem;
    justify-items: center;
  `)}
`
