import { TextStyles } from 'fitify-ui'
import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledFeature = styled.div<{ isOdd: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;

  &:not(:first-child) {
    margin-top: 120px;
  }

  ${(props) =>
    props.isOdd
      ? css`
          flex-direction: row-reverse;
        `
      : undefined}

  ${phone(css`
    &:not(:first-child) {
      margin-top: 48px;
    }
    flex-direction: column-reverse;
  `)};
`

export const StyledFeatureItem = styled.div<{ isOdd: boolean }>`
  width: 50%;

  ${phone(css`
    width: 100%;
  `)};

  > ${TextStyles} {
    max-width: 85%;

    ${phone(css`
      max-width: initial;
    `)};

    ${(props) =>
      props.isOdd
        ? css`
            margin-left: 78px;
            ${phone(css`
              margin-left: initial;
            `)};
          `
        : undefined}
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

export const StyledFeatureItemImage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  img {
    border-radius: 24px;
  }
`
