import { colors, TextStyles } from 'fitify-ui'
import {
  tabletPortrait,
  biggerPhone,
  phone,
} from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledHowItWorksIdContainer = styled.div`
  position: absolute;
  top: -72px;
  height: 72px;

  ${phone(css`
    top: 0;
  `)};
`
export const StyledHowItWorksItem = styled.div`
  background: ${colors.White};
  padding: 32px;
  border-radius: 24px;
  border: 1px solid ${colors.Gray200};
  ${TextStyles} {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`
export const StyledHowItWorksItemWithButton = styled.div`
  height: 100%;
  background: ${colors.White};
  padding: 36px 32px 32px;
  border-radius: 24px;
  border: 1px solid ${colors.Gray200};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  a {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`
export const StyledHowItWorksList = styled.div<{ columns: number }>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 24px;
  grid-template-areas:
    '. .'
    '. .';
  margin-top: 24px;

  ${tabletPortrait(css`
    grid-auto-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: '.';
    gap: 16px;
    margin-top: 24px;
  `)};
  ${biggerPhone(css`
    grid-auto-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: '.';
    gap: 16px;
    margin-top: 24px;
  `)};
  ${phone(css`
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: '.';
    gap: 16px;
    margin-top: 24px;
  `)};
`

export const StyledHowItWorksSection = styled.div`
  position: relative;
  padding-top: 120px;

  ${phone(css`
    padding-top: 80px;
  `)}
`
