import { ImageTypes } from 'fitify-ui-landing/src/@types'
import { PageVariant } from 'fitify-ui-landing/src/@types/PageVariant'
import AnimatedImageGroup from 'fitify-ui-landing/src/components/blocks/AnimatedImageGroup/AnimatedImageGroup'
// load with blurred image
import AnimatedImageGroupItem from 'fitify-ui-landing/src/components/blocks/AnimatedImageGroup/AnimatedImageGroupItem'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import type { RuleSet } from 'styled-components'

import { StyledVisual } from './Visual.Styled'
import { VisualStyledImage } from './VisualImage.Styled'

const Visual = ({
  items,
  imageSrc,
  imageSrcSet,
  imageSizes,
  isScalingSize = false,
  variant,
  additionalImageStyles,
}: {
  items: ImageTypes.Asset[]
  imageSrc: string
  imageSrcSet?: string
  imageSizes?: string
  isScalingSize?: boolean
  variant?: PageVariant
  additionalImageStyles?: RuleSet
}) => {
  return (
    <Section>
      <Container>
        <AnimatedImageGroup>
          {items &&
            items.map((item, index) => {
              return (
                <AnimatedImageGroupItem
                  key={'visual_' + index}
                  width={item.width}
                  label={item.label}
                  value={item.value}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  position={item.position}
                  isScalingSize={isScalingSize}
                />
              )
            })}
          <StyledVisual>
            <VisualStyledImage
              srcSet={imageSrcSet ?? ''}
              sizes={imageSizes}
              src={imageSrc}
              width={'984'}
              height={'327'}
              alt={'Fitify - Visual'}
              loading={'eager'}
              additionalStyles={additionalImageStyles}
              isBusiness={variant === PageVariant.BUSINESS}
            />
          </StyledVisual>
        </AnimatedImageGroup>
      </Container>
    </Section>
  )
}

export default Visual
