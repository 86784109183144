import { Text } from 'fitify-ui'
import { ImageTypes } from 'fitify-ui-landing/src/@types'
import AnimatedImageGroup from 'fitify-ui-landing/src/components/blocks/AnimatedImageGroup/AnimatedImageGroup'
import AnimatedImageGroupItem from 'fitify-ui-landing/src/components/blocks/AnimatedImageGroup/AnimatedImageGroupItem'
import NativeImage from 'fitify-ui-landing/src/components/Image/NativeImage'

import {
  StyledFeature,
  StyledFeatureItem,
  StyledFeatureItemImage,
} from './Feature.Styled'

const Feature = ({
  title,
  content,
  image,
  imageSet,
  isOdd,
  imageAssets,
  headlineVariant,
}: {
  title: string
  content: string
  image: string
  imageSet: string
  isOdd: boolean
  imageAssets?: ImageTypes.Asset[]
  headlineVariant: TextTypesVariants
}) => {
  return (
    <StyledFeature isOdd={isOdd}>
      <StyledFeatureItem isOdd={isOdd}>
        <Text as={'h3'} variant={headlineVariant}>
          {title}
        </Text>
        <Text as={'p'} variant={'feature-content'}>
          {content}
        </Text>
      </StyledFeatureItem>
      <StyledFeatureItem isOdd={isOdd}>
        <AnimatedImageGroup>
          {imageAssets &&
            imageAssets.map((item, index) => {
              return (
                <AnimatedImageGroupItem
                  key={index}
                  width={item.width}
                  label={item.label}
                  value={item.value}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  position={item.position}
                />
              )
            })}
          <StyledFeatureItemImage>
            <NativeImage
              alt={title}
              src={image}
              srcSet={imageSet}
              width={'100%'}
              height={'auto'}
            />
          </StyledFeatureItemImage>
        </AnimatedImageGroup>
      </StyledFeatureItem>
    </StyledFeature>
  )
}

export default Feature
