import { DataTypes } from 'fitify-ui-landing/src/@types'

const howItWorksData: DataTypes.HowItWorksItem[] = [
  {
    icon: 'personal-trainer',
    iconColor: 'Green400',
    title: 'hc_landing_get_matched_title',
    content: 'hc_landing_get_matched_text',
  },
  {
    icon: 'interview',
    iconColor: 'Green400',
    title: 'hc_landing_connect_with_coach_title',
    content: 'hc_landing_connect_with_coach_text',
  },
  {
    icon: 'workout-plan',
    iconColor: 'Green400',
    title: 'hc_landing_get_personal_training_title',
    content: 'hc_landing_get_personal_training_text',
  },
  {
    icon: 'jump-rope',
    iconColor: 'Green400',
    title: 'hc_landing_exercise_when_it_works_title',
    content: 'hc_landing_exercise_when_it_works_text',
  },
  {
    icon: 'meeting',
    iconColor: 'Green400',
    title: 'hc_landing_stay_on_track_title',
    content: 'hc_landing_stay_on_track_text',
  },
]

const businessHowItWorksData: DataTypes.HowItWorksItem[] = [
  {
    icon: 'user-pin',
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_1_title',
    content: 'hc_landing_work_how_it_works_block_1_subtitle',
  },
  {
    icon: 'workout-plan',
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_2_title',
    content: 'hc_landing_work_how_it_works_block_2_subtitle',
  },
  {
    icon: 'team',
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_3_title',
    content: 'hc_landing_work_how_it_works_block_3_subtitle',
  },
  {
    icon: 'spaceship',
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_4_title',
    content: 'hc_landing_work_how_it_works_block_4_subtitle',
  },
]

export { howItWorksData, businessHowItWorksData }
