import { TextStyles, StyledIcon, colors, ThemeColors } from 'fitify-ui'
import {
  biggerPhone,
  phone,
  tabletLandscape,
  tabletPortrait,
  xsPhone,
} from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledAnimatedImageGroupItem = styled.div<{
  position?: [
    number | undefined,
    number | undefined,
    number | undefined,
    number | undefined,
  ]
  width?: number
  iconColor?: ThemeColors
  isScalingSize?: boolean
}>`
  z-index: 200;
  top: 20px;
  left: 150px;

  ${(props) =>
    props.position &&
    css`
      position: absolute;
      top: ${props.position[0]}%;
      right: ${props.position[1]}px;
      bottom: ${props.position[2]}px;
      left: ${props.position[3]}%;
    `}

  ${(props) =>
    props.width
      ? css`
          width: ${props.width}px;
        `
      : css`
          width: 120px;
        `}

  ${TextStyles} {
    margin: 0 !important;
    padding: 0 !important;
  }

  ${StyledIcon} {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    ${(props) =>
      props.iconColor
        ? css`
            color: ${colors[props.iconColor]};
          `
        : css`
            color: ${colors.Black};
          `}
  }

  ${(props) =>
    props.isScalingSize &&
    css`
      ${tabletLandscape(css`
        zoom: 0.9;
      `)}

      ${tabletPortrait(css`
        zoom: 0.75;
      `)}

      ${biggerPhone(css`
        zoom: 0.6;
      `)}
      
      ${phone(css`
        zoom: 0.7;
      `)}

      ${xsPhone(css`
        zoom: 0.65;
      `)}
    `}

  ${(props) =>
    !props.isScalingSize &&
    css`
      ${xsPhone(css`
        zoom: 0.7;
      `)}
    `}
`

export const StyledAnimatedImageGroupItemContent = styled.div`
  display: flex;
  align-items: center;
`
