import { SerializableTimestamp } from 'fitify-types/src/types/common'

export enum Variants {
  MOUNTAIN = 'mountain',
  FLOWER = 'flower',
}
export interface Item {
  userId: string
  userName: string
  avatar: string
  achievementTitle: string
  achievementImage: string
  progress: number
  height: number
  progressPercentage: number | null
  achievementCode: string
  achieved: SerializableTimestamp
  layout: boolean
  achievementType: Variants
}

export interface ShareTexts {
  title: string
  achieved_title: string
  achieved_date: string
}

export interface Props {
  achievement: Item | null
  achievement_image_og_url: string | null
}
