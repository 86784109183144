import { Icon, Text } from 'fitify-ui'
import { ImageTypes } from 'fitify-ui-landing/src/@types'
import { motion, type Variants } from 'framer-motion'

import {
  StyledAnimatedImageGroupItem,
  StyledAnimatedImageGroupItemContent,
} from './AnimatedImageGroupItem.Styled'

const AnimatedImageGroupItem = ({
  position,
  width,
  label,
  value,
  icon,
  iconColor,
  isScalingSize = true,
}: ImageTypes.Asset) => {
  const cardVariants: Variants = {
    offscreen: {
      y: 30,
    },
    onscreen: {
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
        staggerChildren: 1.5,
      },
    },
  }

  return (
    <StyledAnimatedImageGroupItem
      width={width}
      position={position}
      iconColor={iconColor}
      isScalingSize={isScalingSize}
    >
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={cardVariants}
        style={{
          background: '#ffffff',
          borderRadius: '16px',
          padding: '12px 12px 8px',
          height: '77px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <Text
          variant={'animated-image-group-label'}
          color={'Gray600'}
          as={'span'}
          html={label}
        />
        <StyledAnimatedImageGroupItemContent>
          <Icon name={icon} />
          <Text
            variant={'animated-image-group-value'}
            as={'span'}
            html={value}
          />
        </StyledAnimatedImageGroupItemContent>
      </motion.div>
    </StyledAnimatedImageGroupItem>
  )
}

export default AnimatedImageGroupItem
