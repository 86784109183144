import styled from 'styled-components'

export const StyledVisual = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  text-align: center;
`

export const StyledTryItItem = styled.div`
  width: 50%;
`
